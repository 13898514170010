<template>
    <div style="width: fit-content; background-color: white;">
        
        <v-tour name="stockChartTour" ref="page-tour" :steps="steps" :options="{ debug: true }" :callbacks="tourCallbacks"></v-tour>

 <table v-if="namespace !== 'undefined'" style="text-align: left; margin-bottom: 0px; margin-top: 2px; vertical-align: bottom;
               width: 100%;"> 
            <tr style="text-align: left; margin-bottom: 0px; vertical-align: bottom;">
                <td>
            <b-container fluid style="width: auto; margin-top: 0px; background-color: white; margin-left: -15px;">
                <b-row align-h="start" align-v="end" style="background-color: white; width: auto; padding: 1px;" class="row no-gutters">
                    <b-col md="auto" style="margin-right: 1px; margin: 0px -4px -2px 3px; padding: 1px; width: auto; background-color: white">  
                        <stock-box v-bind:namespace="namespace"></stock-box>                      
                    </b-col>
                    <b-col cols="3">
                        <b-button-group style="border: 1px solid gray; background-color: #E8E8E8;
                                        margin: 3px 1px 1px 1px; border-radius: 3px;">      

                            <chart-features v-bind:namespace="namespace"></chart-features>

                            <toggle-single-throw v-if="['admin', 'superadmin'].includes($store.state.user.role)" v-bind:namespace="namespace"
                                                 v-bind:property="'showPlaybackControl'" v-bind:label="'playback'" style="margin: 0px"></toggle-single-throw>
                            <toggle-single-throw v-bind:namespace="namespace"
                                                 v-bind:property="'addProfitLoss'" v-bind:label="'P/L'" style="margin: 0px"></toggle-single-throw>
                            
                            <b-button v-b-toggle.sidebar-help variant="warning" size="sm" style="border: 0px solid darkgray; border-radius: 3px; margin: 1px;">Help</b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
            </b-container>
            </td>
            </tr>
            <tr>
                <td>

                </td>
            </tr>
        </table>
    </div>
</template>

<script>
   
    import stockBox from '@/components/stock-box';
    import toggleSingleThrow from '@/components/toggle-single-throw';
    //import tradeSpecs from '@/components/trade-specs';
    import chartFeatures from "@/components/chart-features";

    export default {
        components: {
            stockBox,
            toggleSingleThrow,
            chartFeatures
        },
        mounted() {
            // console.log("stock-chart-input.vue mounted() starting. this.namespace=", this.namespace);
        },
        props: ['namespace'],
        data: function () {
            return {
                tourCallbacks: {
                    onFinish: this.onTourFinish,
                    onStop: this.onTourFinish,
                    onSkip: this.onTourFinish
                }
            }
        },
        computed: {
            normalizedStockGraphType() {
                return this.$store.getters[this.namespace + "/normalizedStockGraphType"];
            },
            legs(){
                return this.$store.state[this.namespace].legs;
            },
            steps(){
                console.log("steps() starting.");
               // let that = this;
                return  [
                    {
                        target: '#tour-start-' + this.namespace, // We're using document.querySelector() under the hood
                        header: {
                            title: 'Stock Charts Tour',
                        },
                        content: `<p style="text-align: left">You may use the ←, → and ESC keys to navigate through the tour.</p>`,
                        params: {
                            enableScrolling: false
                        },
                    },
                    {
                        target: '#stock-auto-complete-'+ this.namespace, 
                        content: `<p style="text-align: left">Enter the ticker here.</p>`,
                        params: {
                            enableScrolling: false
                        },
                    },
                    {
                        target: '#stock-legs-'+ this.namespace, 
                        content: `<p style="text-align: left">Select either 1 or 2 legs.</p>`,
                        params: {
                            enableScrolling: false
                        },
                    },
                    {
                        target: '#stock-mult1-'+ this.namespace, 
                        content: `<p style="text-align: left">Select the number of shares.</p>`,
                        params: {
                            enableScrolling: false
                        },
                    },
                    {
                        target: '#chart-features-'+this.namespace, 
                        content: `<p style="text-align: left">add text</p>`,
                        params: {
                            enableScrolling: false
                        },
                    },
                    {
                        target: '#profit-loss-toggle-'+this.namespace, 
                        content: `<p style="text-align: left">add text</p>`,
                        params: {
                            enableScrolling: false
                        },
                    },
                    
                 /*   {
                        target: '#page-info-list-item',
                        content: `<p style="text-align: left">For more information, click here.</p>`,
                        params: {
                            placement: 'right',
                            enableScrolling: false
                        },
                        before: function () {
                            return new Promise((resolve) => {
                                // Time-consuming UI/async operation here.
                                console.log("that.$parent.$parent.$parent.$refs.tabsBox.$refs.sidebar=", that.$parent.$parent.$parent.$refs.tabsBox.$refs.sidebar);

                                that.$parent.$parent.$parent.$refs.tabsBox.$refs.sidebar.sidebarClosed = true;
                                that.$parent.$parent.$parent.$refs.tabsBox.$refs.sidebar.updateSelectedSidebarComponentToFirebase(null);

                                setTimeout(function () {
                                    resolve('foo');
                                }, 10);
                            });
                        }
                    },
                    {
                        target: '#selected-sidebar-component',
                        content: `<p style="text-align: left">End of tour.</p>`,
                        params: {
                            placement: 'top',
                            enableScrolling: false
                        },
                        before: function () {
                            return new Promise((resolve) => {
                                // Time-consuming UI/async operation here.
                                console.log("that.$parent.$parent.$parent.$refs.tabsBox.$refs.sidebar=", that.$parent.$parent.$parent.$refs.tabsBox.$refs.sidebar);

                                that.$parent.$parent.$parent.$refs.tabsBox.$refs.sidebar.sidebarClosed = false;
                                that.$parent.$parent.$parent.$refs.tabsBox.$refs.sidebar.updateSelectedSidebarComponentToFirebase("pageInfo");
                                resolve('foo');
                            });
                        }
                    } */
                ]

            }
        },
        methods: {
            tourClickHandler(activeModuleName){
                console.log("tourClickHandler() starting. this.namespace=", this.namespace, " activeModuleName=", activeModuleName);
                if(this.namespace === activeModuleName){
                    this.$tours['stockChartTour'].start();
                }
            },
            onTourFinish(){
                console.log("onTourFinish() starting. this.namespace=", this.namespace);
                this.$parent.$parent.$parent.$refs.tabsBox.$refs.sidebar.sidebarClosed = true;
                this.$parent.$parent.$parent.$refs.tabsBox.$refs.sidebar.updateSelectedSidebarComponentToFirebase(null);
            }
        }
    };

</script>

<style scoped>
    .px-0 {
        padding: 0;
    }
</style>
